import * as Yup from "yup";
const limitAddressOfNumber = /(\d{7,})/;
const limitAddressOfNumber2 = /^\d+$/;
const checkSpace = /^[^\s].*/;
const checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const validFileExtensions = {
  image: ["jpg", "png", "jpeg"],
};

export function isValidFileType(fileName, fileType) {
  return (
    fileName &&
    validFileExtensions[fileType]?.indexOf(fileName?.split(".").pop()) > -1
  );
}

Yup.addMethod(Yup.string, "noLeadingSpace", function (field) {
  return this.test({
    name: "no-leading-space",
    message: `${field} cannot start with a space`,
    test: function (value) {
      if (value) {
        return !value.startsWith(" ");
      }
      return true;
    },
  });
});

export const BusinessLoginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Please enter email")
    .matches(checkEmail, "Please enter a valid email"),
  password: Yup.string()
    .required("Please enter password")
    .noLeadingSpace("Password"),
});

export const BusinessContactUs = Yup.object().shape({
  // name: Yup.string()
  // .required("Name is required")
  // .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
  //   message: " Invalid Name",
  //   excludeEmptyString: true,
  // })
  // .max(30, " Full Name cannot exceed 30 characters"),
  email: Yup.string()
    .matches(checkEmail, "Please enter a valid email")
    .required("Email is required"),

  number: Yup.string()
    .required("Contact number is required")
    .matches(/^(\+\d{1,3}[- .]?)?\d{10}$/, "Invalid contact number"),

  message: Yup.string()
    .required("Message is required")
    .max(200, "Message cannot exceed 200 characters")
    .matches(/^[^\s].*$/, "Message cannot start with a space"),
});

export const BusinessForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .matches(checkEmail, "Please enter a valid email")
    .required("Please enter email"),
});

export const BusinessSendOtpSchema = Yup.object().shape({
  otp: Yup.string()
    .required(" Please enter otp")
    .matches(/^\d{4}$/, " OTP must be a 4-digit number"),
});

export const BusinessResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required(" Please enter new password")
    .noLeadingSpace("Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{6,}$/,
      " Password must be 6 characters, one capital letter, one special character and one number "
    )
    .max(32, " New password cannot exceed 32 characters"),

  confirmPassword: Yup.string()
    .required(" Please enter confirm password")
    .oneOf(
      [Yup.ref("newPassword")],
      " New password and confirm password should be the same"
    ),
});

export const ChangePasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required(" Please enter new password")
    .noLeadingSpace("Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{6,}$/,
      " Password must be 6 characters, one capital letter, one special character and one number "
    )
    .max(32, " New password cannot exceed 32 characters"),

  confirmPassword: Yup.string()
    .required(" Please enter confirm password")
    .oneOf(
      [Yup.ref("newPassword")],
      " New password and confirm password should be the same"
    ),

  oldPassword: Yup.string().required(" Please enter old password"),
});

export const BusinessSignUpSchema = Yup.object().shape({
  fullName: Yup.string()
    .required(" Full name is required")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message: " Invalid full name",
      excludeEmptyString: true,
    })
    .max(32, " Full name cannot exceed 32 characters"),

  // number: Yup.string()
  //   .required(" Contact Number is required")
  //   .matches(/^\d+(\.\d+)?$/, " Contact Number must be exactly 10 digits")

  //   .test("no-dot", "Contact Number is invalid", (value) => {
  //     return value === undefined || !/\./.test(value);
  //   }),

  number: Yup.string()
    .required("Contact number is required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Invalid contact number"),
  // .test("inputEntry", "Contact Number should have digits only", digitsOnly)
  // .test("inputEntry", "Contact Number is not valid", mobilePattern)
  // .min(10, "Contact Number minimum must be 10 digits")
  // .max(10, "Contact Number cannot exceed 10 digits"),

  email: Yup.string()
    .matches(checkEmail, "Please enter a valid email")
    .required("Email is required"),

  companyName: Yup.string()
    .required(" Company name is required")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message: " Invalid company name",
      excludeEmptyString: true,
    })
    .max(32, " Company name cannot exceed 32 characters"),

  jobTitle: Yup.string()
    .required(" Job title is required")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message: " Invalid job title",
      excludeEmptyString: true,
    })
    .max(32, " Job title cannot exceed 32 characters"),

  openingTime: Yup.string().required(" Opening time is required"),
  closingTime: Yup.string().required(" Closing time is required"),
  category: Yup.string().required(" Please select a business category"),
  subCategory: Yup.string().required(" Please select a business subCategory"),

  postalCode: Yup.string()
    .required(" Postal code is required")
    .matches(/^[\dA-Za-z\s-]{1,10}$/, "Invalid postal code"),
  // .matches(/^\d{1,8}$/, " Postal Code cannot exceed 8 digits")
  // .test(" Postal Code cannot contain a dot (.)", (value) => {
  //   return value === undefined || !/\./.test(value);
  // }),

  addressLineOne: Yup.string()
    .required("Address line one is required")
    // .max(64, "Address Line One cannot exceed 64 characters")
    .test(
      "no-six-consecutive-digits",
      "Invalid address line one",
      (value) => !limitAddressOfNumber.test(value)
    )
    .test(
      "no-leading-space",
      "Invalid address line one",
      (value) => !/^\s/.test(value) // Test for no leading space
    )
    .test(
      "no-standalone-special-chars",
      "Invalid address line one",
      (value) => {
        // Test for standalone special characters
        const standaloneSpecialCharsRegex =
          /(^|\s)[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+($|\s)/;
        return !standaloneSpecialCharsRegex.test(value);
      }
    ),

  addressLineTwo: Yup.string()
    .required("Address line two is required")
    .max(64, "Address line two cannot exceed 64 characters")
    .test(
      "no-six-consecutive-digits",
      "Invalid address line two",
      (value) => !limitAddressOfNumber.test(value)
    )
    .test(
      "no-leading-space",
      "Invalid address line two",
      (value) => !/^\s/.test(value) // Test for no leading space
    )
    .test(
      "no-standalone-special-chars",
      "Invalid address line two",
      (value) => {
        // Test for standalone special characters
        const standaloneSpecialCharsRegex =
          /(^|\s)[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+($|\s)/;
        return !standaloneSpecialCharsRegex.test(value);
      }
    ),

  town: Yup.string()
    .required(" Town is required")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message: " Invalid town",
      excludeEmptyString: true,
    })
    .max(30, " Town cannot exceed 30 characters"),

  country: Yup.string()
    .required(" Country is required")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message: " Invalid country",
      excludeEmptyString: true,
    })
    .max(30, " Country cannot exceed 30 characters"),
});

export const ProfileDetailSchema = Yup.object().shape({
  fullName: Yup.string()
    .required(" Full name is required")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message: " Invalid full name",
      excludeEmptyString: true,
    })
    .max(32, " Full name cannot exceed 32 characters"),

  number: Yup.string()
    .required("Contact number is required")
    .matches(/^(\+\d{1,3}[- .]?)?\d{10}$/, "Invalid contact number"),

  email: Yup.string()
    .email(" Invalid email format")
    .matches(
      // /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      /^[a-zA-Z][a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      " Invalid email format"
    )
    .required(" Email is required"),

  companyName: Yup.string()
    .required(" Company name is required")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message: " Invalid company name",
      excludeEmptyString: true,
    })
    .max(32, " Company name cannot exceed 32 characters"),

  jobTitle: Yup.string()
    .required(" Job title is required")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message: " Invalid job title",
      excludeEmptyString: true,
    })
    .max(32, " Job title cannot exceed 32 characters"),

  openingTime: Yup.string().required(" Opening time is required"),
  closingTime: Yup.string().required(" Closing time is required"),

  category: Yup.string().required(" Please select a business category"),
  subCategory: Yup.string().required(" Please select a business subCategory"),

  postalCode: Yup.string()
    .required(" Postal code is required")
    // .matches(/^(\+\d{1,7}[- ]?)?\d{1,7}$/, "Invalid postal code"),
    .matches(/^[\dA-Za-z\s-]{1,10}$/, "Invalid postal code"),
  addressLineOne: Yup.string()
    .required("Address line one is required")
    // .max(64, "Address Line One cannot exceed 64 characters")
    .test(
      "no-six-consecutive-digits",
      "Invalid address line one",
      (value) => !limitAddressOfNumber.test(value)
    )
    .test(
      "no-leading-space",
      "Invalid address line one",
      (value) => !/^\s/.test(value) // Test for no leading space
    )
    .test(
      "no-standalone-special-chars",
      "Invalid address line one",
      (value) => {
        // Test for standalone special characters
        const standaloneSpecialCharsRegex =
          /(^|\s)[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+($|\s)/;
        return !standaloneSpecialCharsRegex.test(value);
      }
    ),

  addressLineTwo: Yup.string()
    .required("Address line two is required")
    .max(64, "Address line two cannot exceed 64 characters")
    .test(
      "no-six-consecutive-digits",
      "Invalid address line two",
      (value) => !limitAddressOfNumber.test(value)
    )
    .test(
      "no-leading-space",
      "Invalid address line two",
      (value) => !/^\s/.test(value) // Test for no leading space
    )
    .test(
      "no-standalone-special-chars",
      "Invalid address line two",
      (value) => {
        // Test for standalone special characters
        const standaloneSpecialCharsRegex =
          /(^|\s)[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+($|\s)/;
        return !standaloneSpecialCharsRegex.test(value);
      }
    ),

  town: Yup.string()
    .required(" Town is required")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message: " Invalid town",
      excludeEmptyString: true,
    })
    .max(30, " Town cannot exceed 30 characters"),

  country: Yup.string()
    .required(" Country is required")
    .matches(/^[a-zA-Z][a-zA-Z\s']*$/, {
      message: " Invalid country",
      excludeEmptyString: true,
    })
    .max(30, " Country cannot exceed 30 characters"),

  // businessImage: Yup.mixed()
  //   .required(" Business Image is required")
  //   .test(
  //     "fileFormat",
  //     "Only JPG, JPEG, and PNG files are allowed",
  //     (value) => {
  //       if (!value) return true; // Skip validation if no file is selected

  //       const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
  //       return allowedFormats.includes(value.type);
  //     }
  //   ),

  // bannerImage: Yup.mixed()
  //   .required("App Image is required")
  //   .test(
  //     "fileFormat",
  //     "Only JPG, JPEG, and PNG files are allowed",
  //     (value) => {
  //       if (!value) return true; // Skip validation if no file is selected

  //       const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
  //       return allowedFormats.includes(value.type);
  //     }
  //   ),

  businessImage: Yup.mixed()
    .required(
      " Business image is required (only JPG, JPEG, and PNG files are allowed)"
    )
    .test(
      "is-valid-type",
      "Please upload only .jpg, .png, .jpeg files",
      (value) => isValidFileType(value && value?.name?.toLowerCase(), "image")
    ),
  bannerImage: Yup.mixed()
    .required(
      "App image is required (only JPG, JPEG, and PNG files are allowed)"
    )
    .test(
      "is-valid-type",
      "Please upload only .jpg, .png, .jpeg files",
      (value) => isValidFileType(value && value?.name?.toLowerCase(), "image")
    ),
});

const imageValidation = Yup.mixed().test(
  "fileFormat",
  "Only JPG, JPEG, and PNG files are allowed",
  (value) => {
    if (!value) return true; // Skip validation if no file is selected

    const fileExtension = value && value.name.split(".").pop().toLowerCase();
    return ["jpg", "jpeg", "png"].includes(fileExtension);
  }
);

const isPresentOrFutureDate = (value) => {
  const selectedDate = new Date(value);
  const currentDate = new Date();

  if (selectedDate < currentDate) {
    return false;
  }

  return true;
};

export const CreateAdvertSchema = Yup.object().shape({
  // name: Yup.string().required('Advert Name is required'),
  name: Yup.string()
    .required("Advert name is required")
    .test(
      "no-leading-space",
      "Invalid advert name",
      (value) => !value.startsWith(" ")
    )
    .test(
      "no-invalid-special-chars",
      "Invalid advert name",
      (value) =>
        !/[!@#$%^&*()_+={}[\]:;<>,.?~\\-]/.test(value) || /[a-zA-Z]/.test(value)
    )
    .test(
      "no-six-consecutive-digits",
      "Invalid advert name",
      (value) => !limitAddressOfNumber2.test(value)
    )
    .max(64, "Advert name cannot exceed 64 characters"),

  startDate: Yup.date().required("Start date is required"),
  // .test("is-present-or-future", "Only select present or future date", isPresentOrFutureDate),

  endDate: Yup.date()
    .required("End date is required")
    .min(Yup.ref("startDate"), "End date must be after start date"),

  noOfVouchers: Yup.number()
    .integer("Please enter valid value")
    .test("is-integer", "Decimal values are not allowed", (value) =>
      Number.isInteger(value)
    )
    .required("Voucher number is required")
    .min(1, "Minimum number of vouchers must be at least one")
    .max(100, "Maximum voucher allowed is 100"),

  redemptionValue: Yup.number()
    .integer("Please enter valid value")
    .required("Redemption value is required")
    .min(1, "Minimum value must be at least one")
    .max(100000, "Maximum value allowed is 100000"),

  minimumSpends: Yup.number()
    .integer("Please enter valid value")
    .required("Minimum spend is required")
    .min(1, "Minimum value must be at least one")
    .max(100000, "Maximum value allowed is 100000"),

  description: Yup.string()
    .required("Description is required")
    .max(350, " Description  cannot exceed 350 characters"),
  // couponCodes: Yup.array().of(Yup.string()).required('Coupon codes are required'),
  image: Yup.mixed()
    .required(
      "Advert image is required (Only JPG, JPEG, and PNG files are allowed)"
    )
    .test(
      "is-valid-type",
      "Please upload only .jpg, .png, .jpeg files",
      (value) => isValidFileType(value && value?.name?.toLowerCase(), "image")
    ),
  // couponCodes:Yup.string().required('Please select coupan code type'),
});

import React from "react";
import Index from "../Index";
import "./Thankyou.css"
import './Thankyou.responsive.css'
import PagesIndex from "../PagesIndex";

const style = {
  position: "absolute", 
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Thankyou=()=> {
  return (
    <div>
      <Index.Box>
        <Index.Box sx={style} className="ugo-modal-box ugo-thank-modal-box">
          <Index.Box>
            <Index.Box>
              <Index.Box className="ugo-modal">
                <Index.Box className="dark-logo-set-modal">
                  <img src={PagesIndex.Svg.blcklogo} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="dark-ugo-logo-text">
                  <Index.Typography variant="body1" component="p" className="">
                    UGO OUT
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="modal-box-text">
                  <Index.Typography variant="body1" component="p" className="">
                    Thank you
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="modal-box-text">
                  <Index.Typography variant="body1" component="p" className="">
                    Your advert is now
                    <br /> live
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="common-btn login-btn ugo-modal-btn common-btn-modal">
                  <PagesIndex.Link to="/business/dashboard">
                    <Index.Button variant="contained" disableRipple>
                      Done
                    </Index.Button>
                  </PagesIndex.Link>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default Thankyou;

export const FailureMessage=()=> {
  return (
    <div>
      <Index.Box>
        <Index.Box sx={style} className="ugo-modal-box ugo-thank-modal-box">
          <Index.Box>
            <Index.Box>
              <Index.Box className="ugo-modal">
                <Index.Box className="dark-logo-set-modal">
                  <img src={PagesIndex.Svg.blcklogo} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="dark-ugo-logo-text">
                  <Index.Typography variant="body1" component="p" className="">
                    UGO OUT
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="modal-box-text">
                  <Index.Typography variant="body1" component="p" className="failed-text" >
                    Transaction Failed!
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="modal-box-text">
                  <Index.Typography variant="body1" component="p" className="">
                   Please try 
                    <br /> again
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="common-btn login-btn ugo-modal-btn common-btn-modal">
                <PagesIndex.Link to="/business/dashboard">
                    <Index.Button variant="contained" disableRipple>
                      Done
                    </Index.Button>
                  </PagesIndex.Link>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}


import React, {  useState } from 'react'
import Index from '../../Index';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Header=(props) =>{

  const [openSidebar, setOpenSidebar] = useState(false);
 const navigate=useNavigate()
  const handleSidebarToogle = () => { 
    document.body.classList['add'](
      'sidebar-active'
    );
    setOpenSidebar(!openSidebar) }

    const businessLogout = () => {
      localStorage.clear();
      navigate("/");
      // toast.success("Logout successfully");
    };

  return (
    <div>
      <Index.Box className="header-main header-flex-main">
        <Index.Box className="header">
          <Index.Box className="header-flex">
          <Index.Box className='ugoout-text'>
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                   UGO OUT ADVERTISERS WEB PROTAL
                      </Index.Typography>

                    </Index.Box>
            <Index.Box className="header-right-content res-header-right-content">
              <Index.Box className="upload-btn-set">
            <Index.Link to='/'>
                      <Index.Button variant="contained" onClick={businessLogout}>
                      <Index.Box classname="logo">
              <img src={Index.Svg.upload} alt="logo" className="logo-img" />
            </Index.Box>
                      </Index.Button>
                      </Index.Link>
                      </Index.Box>
               
            <Index.Box className="res-menu-icon res-icon-set">
                  <Index.MenuIcon className="res-menu" onClick={() => handleSidebarToogle()}/>
                </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  )
}

export default Header
